import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-validator-errors',
    templateUrl: './validator-errors.component.html',
    styleUrls: ['./validator-errors.component.scss'],
    standalone: true,
    imports: [NgIf, TranslateModule],
})
export class ValidatorErrorsComponent {

  @Input() errors?: ValidationErrors | null

  constructor() { }
}
